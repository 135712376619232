<template>
  <div>
  <v-overlay
      :z-index="zIndex"
      :value="overlay"
    >

      <v-btn
        class="white--text"
        color="teal"
        @click="overlay = false"
      >
       {{responseStatus}} click to close
      </v-btn>
    </v-overlay>
    <v-dialog
        v-model="dialogChangeStatusInspector"
        @close="dialogChangeStatusInspector=false"
        max-width="600px"
        app
      >
        <v-card>
          <v-card-title class="headline"
            >Do you want to change status ?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              text
              @click="dialogChangeStatusInspector=!dialogChangeStatusInspector"
              >Cancel</v-btn
            >
            <v-btn color="success" @click="updateStatus">Confirm</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <div><system-bar></system-bar> <admin-menu></admin-menu></div>

    <div>
      <v-card>
        <v-card-title>
          <v-btn @click="redirectTo('/admin/users/create-inspector')">Create User</v-btn>
          <v-btn @click="redirectTo('/admin/users/inspector/setting-commission')">Setting Commission</v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table :headers="headers" :items="inspector">
            <template v-slot:item.actions="{ item }">
              <v-btn @click="redirectTo('/admin/users/edit-inspector/' + item._id)"
                >Edit</v-btn
              >
              <v-btn @click="dialogUpdateStatus(item)"
                >{{ item.status }}</v-btn
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import SystemBar from "../../../library/system-bar/system-bar";
import AdminMenu from "../../menubar/menubar";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  name: "AdminList",
  components: {
    "admin-menu": AdminMenu,
    "system-bar": SystemBar,
  },
  data() {
    return {
      dialogChangeStatusInspector: false,
      editItem: "",
      responseStatus:"",
      zIndex:990,
      overlay:false,
      status: "Active",
      url: process.env.VUE_APP_LOYALTY_API_URI,
      custId: process.env.VUE_APP_OGF_CUST_ID,
      headers: [
        {
          value: "email",
          text: "Email",
        },
        {
          value: "fullname",
          text: "Name/Surname",
        },
        {
          value: "status",
          text: "Status",
        },
        {
          value: "actions",
          text: "Actions",
        },
      ],
      inspector: [],
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      listInspectorUser: "BFC/getInspectorList",
    }),
  },
  methods: {
    ...mapActions({
      setInspectorList: "BFC/setListInspector",
    }),
    redirectTo(path) {
      this.$router.push(path);
    },
    dialogUpdateStatus(item){
        //let payload= {};
        this.editItem = item;
        this.dialogChangeStatusInspector = true;

      },
    async init() {
      let payload = {
        custId: this.custId,
      };

      try {
        await axios
          .post(
            this.url + "/api/bfc/v1/inspector/list",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            // console.log(response);
            console.log(response.data);
            if (response.data.statusCode == 0) {
              console.log(response.data)
              this.setInspectorList(response.data.data);
              console.log(this.listInspectorUser);
              this.inspector = response.data.data.map((item) => {
                //let n = 1;
                return {
                  //no: n,
                  _id: item._id,
                  email: item.memberId.email,
                  fullname: item.memberId.firstname + " " + item.memberId.lastname,
                  status: item.status,
                  //totalCases: 0,
                  //lastPayment: "-",
                };
                //n++;
              });
            } else {
              // show error
              console.log("error response");
            }
          });
      } catch (e) {
        //
        console.log(e);
      }
    },
    async updateStatus() {
      let update  = (this.editItem.status=="Active")? "Inactive":"Active";
      let payload = {
        custId: this.custId,
        id: this.editItem._id,
        update: {
            status:update
          }
      };

      try {
        await axios
          .post(
            this.url + "/api/bfc/v1/inspector/update/status",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            // console.log(response);
            console.log(response.data);
            if (response.data.code == 0) {
              /*
              console.log(response.data)
              this.setInspectorList(response.data.data);
              console.log(this.listInspectorUser);
              this.inspector = this.listInspectorUser.map((item) => {
                //let n = 1;
                return {
                  //no: n,
                  _id: item._id,
                  email: item.memberId.email,
                  fullname: item.memberId.firstname + " " + item.memberId.lastname,
                  status: item.status,
                  //totalCases: 0,
                  //lastPayment: "-",
                };
                //n++;
              });
              */
              this.init();
              this.responseStatus = "Update completed";
            } else {
              // show error
              this.responseStatus = "Update failed";
              console.log("error response");
            }
            this.dialogChangeStatusInspector = false;
            this.overlay = true;
          });
      } catch (e) {
        //
        console.log(e);
      }
    },
  },
};
</script>
